<template>
  <tr v-show="!item.parent_id || item.visible" @click="$emit('row-clicked', item)" :class="`row-item-key-${item.id}`">
    <td
      v-for="header in headers"
      :class="{
        'text-center': !['indicators', 'description'].includes(header.value),
        'p-0': ['indicators', 'description', 'options'].includes(header.value),
        'text-right': header.value === 'indicators',
        'pr-1': header.value === 'indicators',
      }"
    >
      <span v-if="header.value === 'description'" :style="`padding-left: ${(item.level || 0) * 10}px`">
        <v-icon
          v-if="item.has_children"
          @click="
            $event.stopPropagation();
            item.is_open ? $emit('close', item) : $emit('open', item)
          "
        >
          {{ item.is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <v-icon v-else>
          mdi-minus
        </v-icon>
        {{ handleDisplayValue(header.value, item) }}
      </span>
      <template v-else-if="header.value === 'indicators'">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <div v-if="item.new_approved_offers_with_manually_cost_base_or_lead_offer" class="indicator-circle green" v-bind="attrs" v-on="on"></div>
          </template>
          <span>{{ $t('LIVE_LIST.INDICATORS.HAS_NEW_APPROVED_OFFERS') }}</span>
        </v-tooltip>
        <v-tooltip top color="warning">
          <template v-slot:activator="{ on, attrs }">
            <div v-if="item.has_new_not_approved_offers" class="indicator-circle yellow" v-bind="attrs" v-on="on"></div>
          </template>
          <span>{{ $t('LIVE_LIST.INDICATORS.HAS_NEW_NOT_APPROVED_OFFERS') }}</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <div v-if="item.has_expired_offers_with_manually_cost_base_or_lead_offer" class="indicator-circle red" v-bind="attrs" v-on="on"></div>
          </template>
          <span>{{ $t('LIVE_LIST.INDICATORS.HAS_EXPIRED_OFFERS') }}</span>
        </v-tooltip>
      </template>
      <!-- <template v-else-if="['m_packaging', 'm_loading', 'm_incoterms'].includes(header.value)">
        <EditablePricingItem
          :key="item.id"
          :item="item"
          :item-key="header.value"
          :selectItems="[
            'Carton', 'Block'
          ]"
          @item-updated="handleItemUpdated"
        />
      </template> -->
      <template v-else-if="header.value === 'cost_base' && item.is_cost_base_manually_edited">
        <b>{{ item[header.value] }}</b>
      </template>
      <template v-else-if="header.value === 'options'">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              x-small
              color="indigo"
              v-bind="attrs"
              v-on="on"
              @click="
                $event.stopPropagation();
                $emit('row-clicked', item)
              "
            >
              fa fa-edit
            </v-icon>
          </template>
          <span>
            {{ $t('GENERAL.LABELS.OPEN') }}
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        {{ handleDisplayValue(header.value, item) }}
      </template>
    </td>
  </tr>
</template>

<script>
import formatter from "@/common/formatter.service";
import EditablePricingItem from "@/views/pages/liveList/partials/EditablePricingItem.vue";

export default {
  components: {
    EditablePricingItem
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    headers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      //
    }
  },

  mounted() {
    //
  },

  methods: {
    handleItemUpdated(data) {
      //console.log(data);

      this.$emit('item-updated', data);
    },
    handleDisplayValue(itemName, itemObj) {
      return formatter.handleDisplayValue(itemName, itemObj);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>