<template>
  <v-card :disabled="isProcessing">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="success"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar
      color="#c31100"
      dark
    >
      <v-toolbar-title>{{ $t("LIVE_LIST.TITLE") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip left color="warning">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="handlePageRefresh"
          >
            mdi-refresh
          </v-icon>
        </template>
        <span>
          {{ $t('GENERAL.LABELS.REFRESH_DATA') }}
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-card flat class="p-0">
      <v-card-text class="p-0">
        <Table
          v-if="headers"
          ref="live-list-table"
          :key="tableKey"
          :name="tableName"
          :headers="headers"
          :headers-slots="['indicators']"
          :hidden-headers="hiddenHeaders"
          :items="tableItems"
          :total-items="items.length"
          :filters="filters"
          :loading="isTableItemsProcessing"
          :options.sync="tableOptions"
          dense
          fixed-header
          multi-sort
          filterable
          @search="handleSearch"
          @filters-change="handleFiltersChange"
        >
          <template v-slot:top-header-actions>
            <v-select
              v-model="selectedTemp"
              dense
              hide-details
              single-line
              class="top-header-select temperature-select mx-2 my-3"
              :items="temps"
              item-text="value"
              item-value="id"
              value="Frozen"
              :label="$t('GENERAL.LABELS.TEMPERATURE')"
              @change="handleTempChange"
            ></v-select>
            <v-select
              dense
              v-model="customOrderValue"
              hide-details
              single-line
              clearable
              class="top-header-select order-select mx-2 my-3"
              :items="orderSelectItems"
              :label="$t('GENERAL.LABELS.ORDER')"
              @change="handleOrderChange"
            ></v-select>
            <v-spacer />
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="openCloseAllItems"
                >
                  <v-icon>
                    {{ hasClosedItems ? 'mdi-unfold-more-horizontal' : 'mdi-unfold-less-horizontal' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ hasClosedItems ? $t('GENERAL.LABELS.EXPAND_ALL') : $t('GENERAL.LABELS.COLLAPSE_ALL') }}
              </span>
            </v-tooltip>

          </template>
          <template v-slot:header.indicators>
            <div class="text-center">
              <v-icon>
                mdi-alert-circle-outline
              </v-icon>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <LiveListTableRow
              :key="item.item.id"
              :item="item.item"
              :headers="item.headers"
              @open="openItem"
              @close="closeItem"
              @row-clicked="openRow($event.id)"
            />
          </template>
        </Table>
      </v-card-text>
    </v-card>
    <LiveListItemDialog
      v-model="showDialog"
      :itemId="selectedItemId"
      @cost-base-change="handleCostBaseChange"
      @lead-offer-change="handleLeadOfferChange"
      @dialog-close="handleDialogClose"
    />
  </v-card>
</template>

<script>
import { orderBy, debounce, find } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import userStateService from "@/common/userState.service.js";
import expandableTableRowsMixin from "@/common/mixins/expandableTableRows.mixin.js";
import LiveListTableRow from "@/views/pages/liveList/partials/LiveListTableRow.vue";
import LiveListItemDialog from "@/views/pages/liveList/partials/LiveListItemDialog.vue";
import { GET_TEMPS } from "@/store/mappingValues.module";
import {
  GET_LIVE_LIST_POSITIONS_TABLE_COLUMNS,
  GET_LIVE_LIST_POSITIONS_TABLE_FILTERS,
  GET_LIVE_LIST_POSITIONS
} from "@/store/liveList.module";

export default {
  components: {
    Table,
    LiveListTableRow,
    LiveListItemDialog
  },

  mixins: [
    expandableTableRowsMixin
  ],

  data() {
    return {
      tableKey: 1,
      tableName: "live-list-table-v2",
      //Items that comes from the backend
      items: [],
      //Items that are displayed in the table
      tableItems: undefined,
      openedItems: {},
      headers: undefined,
      tableOptions: {
        sortBy: [],
        sortDesc: [false]
      },
      temps: [],
      selectedTemp: undefined,
      customOrderValue: undefined,
      itemsUpdating: true,
      hiddenHeaders: [],
      showDialog: false,
      selectedItemId: undefined,
      isProcessing: true,
      isTableItemsProcessing: true,
      isItemsFetched: false,
      filters: [],
      filtersValues: {},
      searchString: "",
      orderSelectItems: [
        {
          text: this.$t('LIVE_LIST.FILTERS.LAST_AUTO_UPDATED'),
          value: 'last_auto_updated'
        },
        {
          text: this.$t('LIVE_LIST.FILTERS.MANNUALY_SET_COST_BASE'),
          value: 'manually_cost_base'
        },
        {
          text: this.$t('LIVE_LIST.FILTERS.NEW_OFFERS_THAT_ARE_NOT_APPROVED'),
          value: 'new_offers_that_are_not_approved'
        },
        {
          text: this.$t('LIVE_LIST.FILTERS.NEW_APPROVED_OFFERS_WITH_MANNUALY_SET_COST_BASE'),
          value: 'new_approved_offers_with_manually_cost_base'
        }
      ]
    };
  },

  watch: {
    tableOptions: {
      handler (options) {
        this.handleTableOptionsUpdate();
      },
      deep: true
    }
  },

  mounted() {
    this.fetchFilters();

    Promise.all([
      this.fetchTemps(),
      this.fetchHeaders()
    ]).then(() => {
      this.$nextTick(() => {
        this.fetchItems(true);
        this.isProcessing = false;
      });
    });

    this.customOrderValue = userStateService.getState(`table-state.${this.tableName}.customOrderValue`, undefined);
  },

  methods: {
    fetchTemps() {
      return this.$store.dispatch(GET_TEMPS).then((temps) => {
        this.temps = temps;

        this.selectedTemp = userStateService.getState(`live-list.temp`, this.temps[0]?.id);
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleTempChange() {
      userStateService.setState(`live-list.temp`, this.selectedTemp);

      this.fetchItems();
    },

    fetchHeaders() {
      return new Promise((resolve) => {
        this.$store.dispatch(GET_LIVE_LIST_POSITIONS_TABLE_COLUMNS).then((response) => {
          let headers = [
            {
                text: '',
                value: 'indicators',
                sortable: false,
            },
            ...response.visible
          ];

          this.hiddenHeaders = response.hidden;

          headers.push({
            text: this.$t("GENERAL.TABLE.OPTIONS_COLUMN"),
            value: "options",
            sortable: false,
            align: "center",
            class: "options-column"
          });

          this.headers = headers;

          resolve();
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
      });
    },

    fetchFilters() {
      this.$store.dispatch(GET_LIVE_LIST_POSITIONS_TABLE_FILTERS).then((filters) => {
        this.filters = filters;
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    fetchItems(initialFetch = false) {
      this.isTableItemsProcessing = true;

      let params = {
        tempMappingValueId: this.selectedTemp,
        filters: this.filtersValues,
        search: this.searchString
      };

      this.$store.dispatch(GET_LIVE_LIST_POSITIONS, params).then((items) => {
        this.items = items;

        this.openedItems = userStateService.getState(`table-state.${this.tableName}.openedItems`, {});

        this.tableItems = this.prepareTableItems(this.items);

        this.itemsUpdating = false;

        this.isTableItemsProcessing = false;

        this.isItemsFetched = true;

        if (initialFetch && this.$route.query['open-position']) {
          this.openRow(parseInt(this.$route.query['open-position']));

          const queryParams = { ...this.$route.query };

          delete queryParams['open-position'];

          this.$router.replace({ query: queryParams });
        }

      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleFiltersChange(filters) {
      this.filtersValues = filters;

      if (this.isItemsFetched) {
        this.fetchItems();
      }
    },

    handleTableOptionsUpdate(options) {
      if (!this.itemsUpdating) {
        this.customOrderValue = undefined;
        userStateService.setState(`table-state.${this.tableName}.customOrderValue`, undefined);

        this.tableItems = this.prepareTableItems(this.items);

        this.$refs['live-list-table'].$el
          .querySelector('.v-data-table__wrapper').scrollTop = 0;
      }
    },

    handleOrderChange() {
        this.itemsUpdating = true;

        this.$refs['live-list-table'].clearSorting();

        this.$nextTick(() => {
          this.tableItems = this.prepareTableItems(this.items);

          this.$refs['live-list-table'].$el
            .querySelector('.v-data-table__wrapper').scrollTop = 0;

          this.itemsUpdating = false;

          userStateService.setState(`table-state.${this.tableName}.customOrderValue`, this.customOrderValue);
        });
    },

    orderItems(items) {
      let orderCallback = undefined;

      switch (this.customOrderValue) {
        case 'last_auto_updated':
          orderCallback = (a, b) => {
            if (a.auto_updated_date === null && b.auto_updated_date === null) {
              return 0;
            } else if (a.auto_updated_date === null) {
              return 1;
            } else if (b.auto_updated_date === null) {
              return -1;
            }

            return a.auto_updated_date > b.auto_updated_date ? -1 : 1;
          };
        break;

        case 'manually_cost_base':
          orderCallback = (a, b) => {
            return a.is_cost_base_manually_edited && !b.is_cost_base_manually_edited ? -1 : 1;
          };
        break;

        case 'new_offers_that_are_not_approved':
          orderCallback = (a, b) => {
            return a.has_new_not_approved_offers && !b.has_new_not_approved_offers ? -1 : 1;
          };
        break;

        case 'new_approved_offers_with_manually_cost_base':
          orderCallback = (a, b) => {
            return a.new_approved_offers_with_manually_cost_base_or_lead_offer && !b.new_approved_offers_with_manually_cost_base_or_lead_offer ? -1 : 1;
          };
        break;

        default:
          return orderBy(items, this.tableOptions.sortBy, this.tableOptions.sortDesc.map(sort => sort ? 'desc': 'asc'));
        break;
      }

      if (orderCallback) {
        items.sort(orderCallback);
      }

      return items;
    },

    handleSearch: debounce(function (searchString) {
      searchString = searchString.length > 2 ? searchString : '';

      if (this.searchString != searchString) {
        this.searchString = searchString;

        if (this.isItemsFetched) {
          this.fetchItems();

          this.$refs['live-list-table'].$el
            .querySelector('.v-data-table__wrapper').scrollTop = 0;
        }
      }
    }, 1500),

    handleCostBaseChange(event) {
      this.updateItemById(this.items, event.itemId, (item) => {
        item.cost_base = event.costBase;
        item.is_cost_base_manually_edited = event.isCostBaseMannuallyEdited;
        item.indicative_price = event.indicativePrice;
      });

      this.tableItems = this.prepareTableItems(this.items);
    },

    handleLeadOfferChange(event) {
      this.updateItemById(this.items, event.itemId, (item) => {
        const persistentColumns = [
          'id', 'number', 'description', 'meat', 'cost_base', 'indicative_price',
          'auto_updated_date', 'is_cost_base_manually_edited'
        ];

        for (const key in item) {
          if (key != 'id' && event.leadOffer && event.leadOffer.hasOwnProperty(key)) {
            item[key] = event.leadOffer[key];
          } else if (!event.leadOffer && !persistentColumns.includes(key)) {
            item[key] = null;
          }
        }
      });

      this.tableItems = this.prepareTableItems(this.items);
    },

    handleDialogClose(itemId) {
      this.updateItemById(this.items, itemId, (item) => {
        item.new_approved_offers_with_manually_cost_base_or_lead_offer = false;
        item.has_new_not_approved_offers = false;
        item.has_expired_offers_with_manually_cost_base_or_lead_offer = false;
      });

      this.tableItems = this.prepareTableItems(this.items);

      let row = this.$refs['live-list-table'].$el.querySelector(`.row-item-key-${itemId}`);
      if (row) {
        row.classList.add('blink-row');
      }
    },

    updateItemById(items, id, updateFn) {
      items.map((item) => {
        if (item.id === id) {
          updateFn(item);
        }

        if (item.children && item.children.length) {
          this.updateItemById(item.children, id, updateFn);
        }
      });
    },

    openRow(itemId) {
      this.selectedItemId = itemId;
      this.showDialog = true;
    },

    handlePageRefresh() {
      this.handleTempChange();
      this.$forceUpdate();
    }

  }
};
</script>

<style lang="scss" scoped>
.top-header-select {
  font-size: 14px;
}

.temperature-select {
  max-width: 100px;
}

.order-select {
  max-width: 250px;
}
</style>
