import userStateService from "@/common/userState.service.js";

export default {
  computed: {
    hasClosedItems() {
      return this.tableItems && this.tableItems.some(item => item.has_children && !item.is_open);
    }
  },

  methods: {
    prepareTableItems(items, parentId = null, level = 0) {
      items = [...items];

      if (typeof this.applySearch === 'function') {
        items = this.applySearch(items);
      }

      if (typeof this.applyFilters === 'function') {
        items = this.applyFilters(items);
      }

      if (typeof this.orderItems === 'function') {
        items = this.orderItems(items);
      }

      let tableItems = [];

      items.forEach(item => {
        let preparedItem = {...item};
        delete preparedItem.children;

        preparedItem.parent_id = parentId;

        preparedItem.level = level;

        preparedItem.visible = parentId ? (this.openedItems[parentId] || false) : true;

        preparedItem.is_open = this.openedItems[preparedItem.id] || false;

        preparedItem.has_children = !!(item.children && item.children.length);

        tableItems.push(preparedItem);

        if (preparedItem.has_children) {
          let childrenItems = this.prepareTableItems(item.children, item.id, level + 1);

          tableItems = [...tableItems, ...childrenItems];
        }
      });

      return tableItems;
    },

    openItem(item) {
      item.is_open = true;

      let children = this.tableItems.filter(
        child => child.parent_id === item.id
      );

      children.forEach(child => {
        child.visible = true;
      });

      this.openedItems[item.id] = true;

      userStateService.setState(`table-state.${this.tableName}.openedItems`, this.openedItems);
    },

    closeItem(item) {
      item.is_open = false;

      let children = this.tableItems.filter(
        child => child.parent_id === item.id
      );

      children.forEach(child => {
        child.visible = false;

        this.closeItem(child);
      });

      this.openedItems[item.id] = false;

      userStateService.setState(`table-state.${this.tableName}.openedItems`, this.openedItems);
    },

    openCloseAllItems() {
      let value = this.hasClosedItems ? true : false;

      this.tableItems.forEach(item => {
        if (item.has_children) {
          item.is_open = value;

          this.openedItems[item.id] = value;

          this.tableItems.forEach(child => {
            if (child.parent_id === item.id) {
              child.visible = value;
            }
          });
        }
      });

      userStateService.setState(`table-state.${this.tableName}.openedItems`, this.openedItems);
    },
  }
};