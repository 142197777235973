<template>
  <v-dialog
    v-model="show"
    ref="dialog"
    persistent
    max-width="98vw"
    @keydown.esc="closeDialog"
    @keydown.enter="closeDialog"
    @close="closeDialog"
  >
    <v-card :disabled="positionLoading">
      <v-card-title class="p-3 sticky-header">
        <div class="d-flex justify-content-between w-100 pb-2 pt-2">
          <span>
            {{ liveListPosition?.group_mapping_value_name }} -
            {{ liveListPosition?.product_mapping_value_name }} -
            {{ liveListPosition?.temp_mapping_value_name }}
          </span>
          <div>
            <!-- <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="indigo"
                  class="mx-1"
                  @click=""
                >
                  mdi-refresh
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.REFRESH_DATA') }}
              </span>
            </v-tooltip> -->
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="indigo"
                  class="mx-1"
                  @click="closeDialog"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.CLOSE') }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="p-0">
        <div class="d-flex flex-wrap p-2">
          <v-alert
            dense
            border="left"
            color="info"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            <v-icon color="white">mdi-coin</v-icon>
            &nbsp;
            {{ $t('LIVE_LIST.COST_BASE') }}:
            &nbsp;
            <strong v-if="liveListPosition?.approved_offers_count">
              <input
                type="text"
                v-model="costBase"
                class="price-input"
              />
            </strong>
            <strong v-else>{{ costBase }}</strong>
            <v-icon v-if="isCostBaseMannuallyEdited" color="white" @click="handleCostBaseRefresh">mdi-refresh</v-icon>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('LIVE_LIST.MIN_PRICE_OF_RANK') }} 1:
            &nbsp;
            <strong>{{ liveListPosition?.rank_1_min_price > 0 ? liveListPosition?.rank_1_min_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('LIVE_LIST.MIN_PRICE_OF_RANK') }} 2:
            &nbsp;
            <strong>{{ liveListPosition?.rank_2_min_price > 0 ? liveListPosition?.rank_2_min_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('LIVE_LIST.MAX_PRICE') }}:
            &nbsp;
            <strong>{{ liveListPosition?.max_price > 0 ? liveListPosition?.max_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('LIVE_LIST.WEIGHTED_AVERAGE_PRICE') }}:
            &nbsp;
            <strong>{{ liveListPosition?.avg_price > 0 ? liveListPosition?.avg_price : '-' }}</strong>
          </v-alert>
        </div>
        <Table
          v-if="headers && itemId"
          ref="live-list-dialog-table"
          :key="tableKey"
          name="live-list-dialog-table-v2"
          :headers="headers"
          :headers-slots="['indicators', 'approved']"
          :hidden-headers="hiddenHeaders"
          :server-data-options="{
            method: tableItemsMethod,
            params: {
              id: itemId,
              customFilter: filterValue
            }
          }"
          :filters="filters"
          :persistent-filters="false"
          dense
          stripped
          fixed-header
          multi-sort
          filterable
        >
          <template v-slot:top-header-actions>
            <v-select
              dense
              hide-details
              single-line
              clearable
              class="top-header-select filter-select mx-2 my-3"
              :items="orderSelectItems"
              v-model="filterValue"
              :label="$t('GENERAL.LABELS.FILTER')"
              @change="handleFilterChange"
            ></v-select>
            <v-spacer />
          </template>
          <template v-slot:header.approved="{ header }">
            <div class="text-center p-0 select-column">
              {{ header.text }}
              <div class="d-flex align-items-center justify-content-center">
                <v-checkbox
                  :key="allApprovedKey"
                  hide-details
                  class="select-checkbox"
                  v-model="allApprovedValue"
                  :indeterminate="liveListPosition?.approved_offers_count > 0"
                  :disabled="positionLoading"
                  @change="handleAllOffersApprovedChange"
                />
              </div>
            </div>
          </template>
          <template v-slot:header.indicators>
            <div class="text-center">
              <v-icon>
                mdi-alert-circle-outline
              </v-icon>
            </div>
          </template>
          <template v-slot:item.approved="{ item }">
            <div class="d-flex justify-content-center">
              <v-checkbox
                hide-details
                class="select-checkbox"
                :input-value="item.item.is_approved"
                @change="handleOfferApprovedChanged(item.item, $event)"
              />
            </div>
          </template>
          <template v-slot:item.lead="{ item }">
            <div class="d-flex justify-content-center">
              <v-checkbox
                hide-details
                class="select-checkbox"
                v-model="item.item.is_lead"
                :disabled="item.item.is_lead || !item.item.is_approved"
                @change="handleLeadOfferChange(item.item)"
              />
            </div>
          </template>
          <template v-slot:item.indicators="{ item }">
            <v-tooltip top color="success">
              <template v-slot:activator="{ on, attrs }">
                <div v-if="item.item.is_auto_approved" class="indicator-circle green" v-bind="attrs" v-on="on"></div>
              </template>
              <span>{{ $t('LIVE_LIST.INDICATORS.AUTO_APPROVED') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.price="{ item }">
            <v-tooltip v-if="item.item.priceData && item.item.priceData.isConverted" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                class="set-cursor-pointer text-warning font-weight-bold"
                v-bind="attrs"
                v-on="on"
                >
                  {{ item.item.price }}
                </span>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.CONVERTED_FROM') }}: {{ item.item.priceData.offerCurrency }}<br />
                {{ $t('GENERAL.LABELS.EXCHANGE_RATE') }}: {{ item.item.priceData.exchangeRate }}<br />
                {{ $t('GENERAL.LABELS.UNCONVERTED_PRICE') }}: {{ item.item.priceData.baseOfferPrice }}
              </span>
            </v-tooltip>
            <span v-else>{{ item.item.price }}</span>
          </template>
          <template v-slot:item.id="{ item }">
            <router-link
              v-if="hasPermissions('offers-edit')"
              :to="{
                name: 'edit-offer',
                params: {
                  id: item.item.vendor_offer_set_id
                }
              }"
              class="set-cursor-pointer"
              target="_blank"
            >
              {{ item.item.id }}
            </router-link>
            <span v-else>{{ item.item.id }}</span>
          </template>
        </Table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import userStateService from "@/common/userState.service.js";
import Table from "@/views/partials/v-table/Table.vue";
import {
  GET_LIVE_LIST_POSITION,
  GET_LIVE_LIST_POSITION_OFFERS_TABLE_COLUMNS,
  GET_LIVE_LIST_POSITION_OFFERS_TABLE_FILTERS,
  GET_LIVE_LIST_POSITION_OFFERS,
  UPDATE_LIVE_LIST_POSITION_LAST_VIEW_DATE,
  UPDATE_LIVE_LIST_POSITION_COST_BASE,
  REFRESH_LIVE_LIST_POSITION_COST_BASE,
  UPDATE_LIVE_LIST_POSITION_LEAD_OFFER,
  UPDATE_LIVE_LIST_POSITION_APPROVED,
  UPDATE_LIVE_LIST_POSITION_ALL_APPROVED
} from "@/store/liveList.module";

export default {
  components: {
    Table
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    itemId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      show: this.value,
      tableKey: 1,
      headers: undefined,
      allApprovedKey: 1,
      allApprovedValue: false,
      hiddenHeaders: undefined,
      positionLoading: false,
      liveListPosition: null,
      tableItemsMethod: GET_LIVE_LIST_POSITION_OFFERS,
      costBase: 0,
      isCostBaseMannuallyEdited: false,
      filterValue: null,
      orderSelectItems: [
        {
          text: this.$t('LIVE_LIST.FILTERS.NEWLY_ARRIVED_OFFERS'),
          value: 'newly_arrived_offers'
        },
      ],
      filters: []
    }
  },

  mounted() {
    this.fetchHeaders();
    this.filterValue = userStateService.getState(`live-list-dialog.filter-value`, null);
  },

  watch: {
    value() {
      this.show = this.value;

      if (this.value) {
        this.handleDialogOpen();
      }
    },

    costBase() {
      if (!this.positionLoading) {
        if (isNaN(this.costBase) || this.costBase < 0) {
          this.costBase = 0;
        }

        this.handleCostBaseChange();
      }
    },

    liveListPosition: {
      handler() {
        this.isCostBaseMannuallyEdited = !!this.liveListPosition?.cost_base_updated_by;
        this.allApprovedValue = this.liveListPosition?.approved_offers_count > 0;
        this.allApprovedKey++;
      },
      deep: true
    },
  },

  methods: {
    fetchHeaders() {
      this.$store.dispatch(GET_LIVE_LIST_POSITION_OFFERS_TABLE_COLUMNS).then((response) => {
        this.headers = [
          {
            text: this.$t('LIVE_LIST.APPROVED'),
            value: 'approved',
            sortable: false,
            align: 'center',
            padding: false,
            class: 'p-0 select-column'
          },
          {
            text: this.$t('LIVE_LIST.LEAD'),
            value: 'lead',
            sortable: false,
            align: 'center',
            padding: false,
            class: 'p-0 select-column'
          },
          {
              text: '',
              value: 'indicators',
              sortable: false,
              align: 'center'
          },
            ...response.visible
          ];

          this.hiddenHeaders = response.hidden;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleDialogOpen() {
      this.fetchPosition();
      this.fetchFilters();

      this.tableKey++;
    },

    fetchFilters() {
      this.$store.dispatch(GET_LIVE_LIST_POSITION_OFFERS_TABLE_FILTERS, { id: this.itemId })
        .then((filters) => {
          this.filters = filters;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchPosition() {
      this.positionLoading = true;

      this.$store.dispatch(GET_LIVE_LIST_POSITION, this.itemId).then((position) => {
        this.liveListPosition = position;

        this.costBase = this.liveListPosition.cost_base;

        this.$nextTick(() => {
          this.positionLoading = false;
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleCostBaseChange: debounce(function () {
      let params = {
        id: this.itemId,
        cost_base: this.costBase
      };

      this.$store.dispatch(UPDATE_LIVE_LIST_POSITION_COST_BASE, params)
      .then((response) => {
        this.isCostBaseMannuallyEdited = true;

        this.$emit('cost-base-change', {
          itemId: this.itemId,
          costBase: response.cost_base,
          isCostBaseMannuallyEdited: true,
          indicativePrice: response.indicative_price
        });
      }).catch(() => {
        this.positionLoading = true;

        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    }, 1500),

    handleCostBaseRefresh() {
      this.positionLoading = true;

      let params = {
        id: this.itemId
      };

      this.$store.dispatch(REFRESH_LIVE_LIST_POSITION_COST_BASE, params)
      .then((response) => {
        this.costBase = response.cost_base;

        this.$nextTick(() => {
          this.positionLoading = false;
        });

        this.isCostBaseMannuallyEdited = false;

        this.$emit('cost-base-change', {
          itemId: this.itemId,
          costBase: response.cost_base,
          isCostBaseMannuallyEdited: false,
          indicativePrice: response.indicative_price
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleOfferApprovedChanged(offer, value) {
      this.positionLoading = true;

      offer.is_approved = value;
      offer.is_auto_approved = false;

      let params = {
        id: this.itemId,
        offer_id: offer.id,
        value: value
      };

      this.$store.dispatch(UPDATE_LIVE_LIST_POSITION_APPROVED, params)
      .then((response) => {
        let isChangedLeadOffer = response.lead_offer_id !== this.liveListPosition.lead_offer_id;

        this.liveListPosition = response;

        this.costBase = response.cost_base;

        offer.is_lead = response.lead_offer_id === offer.id;

        this.$refs['live-list-dialog-table'].serverItems.map(item => {
          item.is_lead = response.lead_offer_id === item.id;
        });

        this.$emit('cost-base-change', {
          itemId: this.itemId,
          costBase: response.cost_base,
          isCostBaseMannuallyEdited: !!response.cost_base_updated_by,
          indicativePrice: response.indicative_price
        });

        this.$nextTick(() => {
          if (offer.is_lead || isChangedLeadOffer) {
            this.$emit('lead-offer-change', {
              itemId: this.itemId,
              leadOffer: response.lead_offer_data
            });
          }

          this.positionLoading = false;
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleAllOffersApprovedChange(value) {
      this.positionLoading = true;

      let params = {
        id: this.itemId,
        value: value
      };

      this.$store.dispatch(UPDATE_LIVE_LIST_POSITION_ALL_APPROVED, params)
      .then((response) => {
        this.liveListPosition = response;

        this.costBase = response.cost_base;

        this.$refs['live-list-dialog-table'].serverItems.map(item => {
          item.is_approved = value;
          item.is_auto_approved = false;
          item.is_lead = value && this.liveListPosition.lead_offer_id === item.id;

          if (value) {
            item.is_auto_approved = false;
          }
        });

        this.$emit('cost-base-change', {
          itemId: this.itemId,
          costBase: response.cost_base,
          isCostBaseMannuallyEdited: !!response.cost_base_updated_by,
          indicativePrice: response.indicative_price
        });

        this.$nextTick(() => {
          this.$emit('lead-offer-change', {
            itemId: this.itemId,
            leadOffer: response.lead_offer_data
          });

          this.positionLoading = false;
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleLeadOfferChange(offer) {
      this.positionLoading = true;

      let params = {
        id: this.itemId,
        lead_offer_id: offer.id
      };

      this.$store.dispatch(UPDATE_LIVE_LIST_POSITION_LEAD_OFFER, params)
      .then((response) => {
        this.positionLoading = false;

        this.$emit('lead-offer-change', {
          itemId: this.itemId,
          leadOffer: offer
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });

      this.$refs['live-list-dialog-table'].serverItems.map((tableOffer) => {
        if (tableOffer.id !== offer.id) {
          tableOffer.is_lead = false;
        }
      });
    },

    handleFilterChange(value) {
      this.$nextTick(() => {
        this.$refs['live-list-dialog-table'].fetchServerData();
      });

      userStateService.setState(`live-list-dialog.filter-value`, value);
    },

    closeDialog() {
      let params = {
        id: this.itemId
      };

      this.$store.dispatch(UPDATE_LIVE_LIST_POSITION_LAST_VIEW_DATE, params);

      this.show = false;
      this.$emit('input', false);
      this.$emit('dialog-close', this.itemId);
    }
  }
}
</script>

<style lang="scss" scoped>
.live-list-dialog-alert {
  font-size: 14px;
  padding: 2px 6px;
  color: white;

  :deep(.v-alert__content) {
    display: flex;
    align-items: center;
  }
}

.price-input,
.price-input:focus {
  width: 30px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

:deep(.v-alert__icon) {
  margin-right: 8px !important;
}

.filter-select {
  max-width: 200px;
}
</style>