var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.parent_id || _vm.item.visible),expression:"!item.parent_id || item.visible"}],class:`row-item-key-${_vm.item.id}`,on:{"click":function($event){return _vm.$emit('row-clicked', _vm.item)}}},_vm._l((_vm.headers),function(header){return _c('td',{class:{
      'text-center': !['indicators', 'description'].includes(header.value),
      'p-0': ['indicators', 'description', 'options'].includes(header.value),
      'text-right': header.value === 'indicators',
      'pr-1': header.value === 'indicators',
    }},[(header.value === 'description')?_c('span',{style:(`padding-left: ${(_vm.item.level || 0) * 10}px`)},[(_vm.item.has_children)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();
          _vm.item.is_open ? _vm.$emit('close', _vm.item) : _vm.$emit('open', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.is_open ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_c('v-icon',[_vm._v(" mdi-minus ")]),_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" ")],1):(header.value === 'indicators')?[_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.item.new_approved_offers_with_manually_cost_base_or_lead_offer)?_c('div',_vm._g(_vm._b({staticClass:"indicator-circle green"},'div',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('LIVE_LIST.INDICATORS.HAS_NEW_APPROVED_OFFERS')))])]),_c('v-tooltip',{attrs:{"top":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.item.has_new_not_approved_offers)?_c('div',_vm._g(_vm._b({staticClass:"indicator-circle yellow"},'div',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('LIVE_LIST.INDICATORS.HAS_NEW_NOT_APPROVED_OFFERS')))])]),_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.item.has_expired_offers_with_manually_cost_base_or_lead_offer)?_c('div',_vm._g(_vm._b({staticClass:"indicator-circle red"},'div',attrs,false),on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('LIVE_LIST.INDICATORS.HAS_EXPIRED_OFFERS')))])])]:(header.value === 'cost_base' && _vm.item.is_cost_base_manually_edited)?[_c('b',[_vm._v(_vm._s(_vm.item[header.value]))])]:(header.value === 'options')?[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();
              _vm.$emit('row-clicked', _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-edit ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.OPEN'))+" ")])])]:[_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" ")]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }