<template>
  <div class="d-flex align-items-center justify-content-center">
    <v-menu
      bottom
      left
      :close-on-content-click="false"
      v-model="isEditMode"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="set-cursor-pointer"
          v-on="on"
        >
          {{ item[itemKey] }}
        </div>
      </template>
      <v-card class="p-2">
        <v-autocomplete
          solo
          dense
          ref="input"
          :value="item[itemKey]"
          :items="selectItems"
          @change="handleValueChange($event)"
        />
      </v-card>
    </v-menu>
    <v-menu
      bottom
      left
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="ml-1"
          small
          color="indigo"
          v-bind="attrs"
          v-on="on"
        >
          mdi-format-list-bulleted-square
        </v-icon>
      </template>
      <v-card class="p-2">
        Carton&nbsp;<span class="text-green">({{ $t('GENERAL.LABELS.LEAD') }})</span>
        <br />
        Block
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    itemKey: {
      type: String,
      required: true
    },

    selectItems: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isEditMode: false
    }
  },

  methods: {
    handleValueChange(value) {
      this.isEditMode = false;
      this.$emit('item-updated', {
        id: this.item.id,
        key: this.itemKey,
        value: value
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.text-green {
  color: green;
}

:deep(.v-text-field__details) {
  display: none;
}

:deep(.v-input__slot) {
  margin-bottom: 0 !important;
}
</style>